@tailwind base;
@tailwind components;
@tailwind utilities;

@import './themes/autosuggest.css';
@import './themes/slider.css';
@import './themes/satellite.css';
@import 'react-slick-styles/slick/slick';

html {
  scroll-behavior: smooth;
}

@layer base {
  #sidebar > h4,
  #sidebar > div > form > h4 {
    @apply text-sage-700;
  }
  #categories > li {
    @apply list-none ml-0 hover:text-gray-500;
  }
}

@layer components {
  #blog > section > * {
    @apply text-sage-600;
  }

  #blog > section#hero > div > div > h2 {
    @apply text-sage-700;
  }

  #post article > * {
    @apply text-gray-500 decoration-gray-300;
  }
  #post article h1 {
    @apply text-5xl mb-6 text-sage-600;
  }
  #post > article h2 {
    @apply text-5xl mb-6;
  }
  #post > article h3 {
    @apply text-4xl mb-6 text-sage-600 leading-10;
  }
  #post > article h4,
  #post > article h4 b {
    @apply text-3xl font-bold mb-6 text-sage-700;
  }

  #post > article h5 {
    @apply text-2xl font-bold mb-6 text-sage-700;
  }
  #post > article p {
    @apply text-lg mb-6 leading-8;
  }
  #post > article img {
    @apply rounded-md w-full;
  }

  #post > article ul {
    @apply mb-6;
  }

  #post > article li {
    @apply list-disc marker:text-teal-500 ml-6 text-lg text-gray-600 leading-8 mb-3;
  }

  #post-new article > * {
    @apply text-[#334155] decoration-[#334155];
  }
  #post-new article h1 {
    @apply text-4xl md:text-5xl text-[#181A2A] mt-16 mb-5;
  }
  #post-new > article h2 {
    @apply text-4xl md:text-5xl text-[#181A2A] mt-16 mb-5;
  }
  #post-new > article h3 {
    @apply text-3xl md:text-4xl text-[#181A2A] leading-10 mt-10 mb-5;
  }
  #post-new > article h4,
  #post-new > article h4 b {
    @apply text-2xl md:text-3xl font-bold text-[#181A2A] mt-10 mb-5;
  }

  #post-new > article h5 {
    @apply text-xl md:text-2xl font-bold text-[#181A2A] mt-10 mb-5;
  }
  #post-new > article p {
    @apply text-xl md:text-[1.375rem] leading-9 mb-12;
  }
  #post-new > article img {
    @apply rounded-2xl w-full mt-12;
  }

  #post-new > article ul {
    @apply mb-5;
  }

  #post-new > article li {
    @apply list-disc marker:text-teal-500 ml-6 text-lg text-[#334155] leading-8 mb-3;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.force-rtl {
  direction: rtl;
}

.force-ltr {
  direction: ltr;
}

/* React Slick Custom */

.slick-disabled {
  display: none !important;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.slick-slide > div {
  height: 100% !important;
}
